import React from "react";
import { VictoryAxis, VictoryBar, VictoryChart, VictoryLabel, VictoryPie, VictoryTooltip } from 'victory';
import { PublicLayout } from '../components/layout';

class CustomFlyout extends React.Component {
    render() {
        const { x, y, orientation } = this.props;
        const newY = orientation === "bottom" ? y - 35 : y + 35;
        return (
            <g>
                <circle cx={x} cy={newY} r="35" stroke="tomato" fill="none" />
                <circle cx={x} cy={newY} r="40" stroke="orange" fill="none" />
                <circle cx={x} cy={newY} r="45" stroke="gold" fill="none" />
            </g>
        );
    }
}

const IndexPage = () => {
    return <PublicLayout>
        <h4>Weekly summary </h4>
        <svg viewBox="-50 -40 500 500">
            <VictoryPie
                standalone={false}
                colorScale={["navy", "orange", "gold", "cyan", "tomato"]}
                style={{
                    data: {
                        fillOpacity: 0.6,
                    },
                    labels: {
                        fill: "black",
                        padding: 10
                    }
                }}
                innerRadius={100}
                padAngle={2}
                data={[
                    { x: "Netflix $17", y: 17 },
                    { x: "Firebase $20", y: 20 },
                    { x: "Milk $5", y: 5 }
                ]}
                animate={{ duration: 2000, delay: 2000, onLoad: 2000 }}
            />
            {<VictoryLabel
                textAnchor="middle"
                style={{ fontSize: 50 }}
                x={200} y={200}
                text="$42"
            />}
        </svg>
        <h4>Weekly day by day</h4>
        <VictoryChart
            domain={{ x: [0, 8] }} >
            <VictoryAxis dependentAxis
                tickFormat={(tick) => `$${tick}`}
            />
            <VictoryAxis

            />
            <VictoryBar
                labelComponent={
                    <VictoryTooltip
                        flyoutComponent={<CustomFlyout />}
                    />
                }
                data={[
                    { x: "Mo", y: 17, fill: "blue", label: "Netflix" },
                    { x: "Tu", y: 0, label: "" },
                    { x: "We", y: 0, label: "" },
                    { x: "Th", y: 20, fill: "orange", label: "Firebase.." },
                    { x: "Fr", y: 5, fill: "gold", label: "Milk" },
                    { x: "Sa", y: 0, label: "" },
                    { x: "Su", y: 0, label: "" }
                ]}
                style={{
                    data: {
                        fill: ({ datum }) => datum.fill,
                        width: 15,
                        fillOpacity: 0.6
                    },

                    labels: { fill: "black" }
                }}
            />
        </VictoryChart>



    </PublicLayout>
}

export default IndexPage
